import React, { useEffect, useState, useContext } from "react";
import { CompanyContext } from '../../global_setting/Store';
import config from '../../config.json'
import GoogleMapReact from "google-map-react";

// google map marker component
const Marker = () => {
  return <div className={`google__map__marker__details`}></div>;
};

const styles = {
  width: "100%",
  minHeight: "380px"
};

const GoogleMapComponent = () => {
  // const [map, setMap] = useState(null);
  // const mapContainer = useRef(null);
  const [companyDetails] = useContext(CompanyContext);
  const [mapDetails, setMapDetails] = useState();

  const COORDS = { lat: 26, lng: 78 }
  const ZOOM = 12;

  const [mapCenter, setMapCenter] = useState(COORDS);
  const [mapZoom, setMapZoom] = useState(ZOOM);

  var company_id_val = companyDetails && companyDetails.company_id;

  useEffect(() => {
    (async function () {
      try {
        if (company_id_val) {
          var company_id = companyDetails.company_id
          var template_id = companyDetails.template_id
          const mapData = await fetch(
            config.apiEndpoint + '/wb-frontview/get-frontview-map-data/' + company_id + '/' + template_id
          );
          const result = await mapData.json();
          setMapDetails({ "hotel_details": result && result.hotel_details });

        }
      } catch (e) {
        console.error(e);
      }
    })();
  }, [company_id_val])


  useEffect(() => {

    if (mapDetails && mapDetails.hotel_details) {
      let lat = parseFloat(mapDetails.hotel_details.latitude);
      let lng = parseFloat(mapDetails.hotel_details.longitude);

      setMapCenter({ lat: lat, lng: lng });
      setMapZoom(16);
    }

  }, [mapDetails]);

  return (
    <div style={styles}>
      {/* <div ref={el => (mapContainer.current = el)} /> */}
      <GoogleMapReact
        bootstrapURLKeys={{
          key: "AIzaSyDhShnWZr1GVyY4fI8Xu6d6l4V954Mlktg",
        }}
        center={mapCenter}
        zoom={mapZoom}

      >
        <Marker lat={mapCenter.lat} lng={mapCenter.lng} />
      </GoogleMapReact>
    </div>

  )


};

export default GoogleMapComponent;